import React, { useEffect } from "react"
import { graphql, Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Img from "gatsby-image"
import styled from "styled-components"
import FadeIn from 'react-fade-in';
import SEO from "../components/seo"


const IndexPage = ( { data } ) => {

  
      return(
        <div>
          <SEO 
          pageType="website"
          linkTitle={data.home.landingPageLinkTitle}
          pageDescription={data.home.landingPageLinkDescription.landingPageLinkDescription}
          pageURL={data.site.siteMetadata.url}
          pageTitle={data.home.landingPageTitle}
          linkImage={`https:${data.home.landingPageImage.file.url}`}
          />
          <Laptop>
            <BackgroundImage fluid={data.featured.childImageSharp.fluid} >
              <FrontPage>
                <FadeIn transitionDuration={3000}>
                  <InnerWrapper>
                    <Logo fluid={data.logo.childImageSharp.fluid} />
                    <Tagline>Independent Community Activist</Tagline>
                    <EntryButton to="/about" >Find out more</EntryButton>
                  </InnerWrapper>
                </FadeIn>
              </FrontPage>
            </BackgroundImage>
          </Laptop>
          <Tablet>
            <BackgroundImage style={{ backgroundPositionX: "-103vw" }} fluid={data.featured.childImageSharp.fluid} >
              <FrontPage>
                <FadeIn transitionDuration={3000}>
                  <InnerWrapper>
                    <Logo fluid={data.logo.childImageSharp.fluid} />
                    <Tagline style={{ fontSize: "2.5vw", paddingBotton: "0.2vw", marginTop: "20px" }}>Independent Community Activist</Tagline>
                    <EntryButton to="/about" style={{ fontSize: "2.5vw", borderRadius: "5px" }}>Find out more</EntryButton>
                  </InnerWrapper>
                </FadeIn>
              </FrontPage>
            </BackgroundImage>
          </Tablet>
          <Mobile>
            <BackgroundImage style={{ backgroundPosition: "center top" }} fluid={data.mobile.childImageSharp.fluid} >
              <MobileWrapper>
                <FadeIn transitionDuration={3000}>
                  <InnerWrapper style={{ marginTop: "7vw" }}>
                    <Logo fluid={data.logo.childImageSharp.fluid} />
                  </InnerWrapper>
                </FadeIn>
              </MobileWrapper>
              
            </BackgroundImage>
            <MobileButton to="/about">Find out more</MobileButton>
          </Mobile>
        </div>
     )
   
};


export default IndexPage;

export const pageQuery = graphql`
  query {
    featured: file(relativePath: {eq: "gary-midshot-cropped.png"}) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    } 
    mobile: file(relativePath: {eq: "gary-mobile-front.png"}) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    } 
    logo: file(relativePath: {eq: "gary-website-logo-white.png"}) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    home: contentfulSiteSettings(title: {eq: "Site Settings"}) {
      landingPageLinkTitle
      landingPageLinkDescription {
        landingPageLinkDescription
      }
      landingPageImage {
        file {
          url
        }
      }
      landingPageTitle
    }
    site {
      siteMetadata {
        url
      }
    }   
  }
`;

const FrontPage = styled.div`
  height: 100vh !important;
  backgroung-color: transparent !important;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: baseline;
`
const InnerWrapper = styled.div`
  margin-left: 5vw;
  margin-top: 2vw;
`

const Logo = styled(Img)`
  width: 40vw;
  opacity: 100%;
  transition:opacity 350ms;

`

const Tagline = styled.h1`
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  color: white;
  font-size: 3vw;
  padding-bottom: 1vw;
  opacity: 100%;
  transition:opacity 350ms;
`

const EntryButton = styled(Link)`
  text-decoration: none;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  color: white;
  font-size: 3vw;
  padding: 10px 20px;
  background-color: #00aeef;
  border-radius: 15px;
  cursor: pointer;
  opacity: 100%;
  transition:opacity 350ms;
  &:hover{
    background-color: white;
    color: #00aeef;
    transition: ease-in-out 350ms;
  }
  
`

const MobileWrapper = styled.div`
  height: 90vh !important;
  
  backgroung-color: transparent !important;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: baseline;
`

const MobileButton = styled(Link)`
  text-decoration: none;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  color: white;
  font-size: 8vw;
  text-align: center !important;
  background-color: #00aeef;
  cursor: pointer;
  opacity: 100%;
  height: 10vh !important;
  width: 100% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;

`

const Laptop = styled.div`
  @media (max-width: 769px) {
    display: none;
  }
`

const Tablet = styled.div`
  @media (min-width: 769px){
    display: none;
  }

  @media (max-width: 451px){
    display: none;
  }
`

const Mobile = styled.div`
  @media (min-width: 451px) {
    display: none;
  }
`


